/* Fonts */
@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-BdCn20.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-BdCn20.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Bd2.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Bd2.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-BdCn20Obl.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-BdCn20Obl.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Bold.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Cn18Obl.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Cn18Obl.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Obl.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Obl.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Cn18.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Cn18.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url("../public/fonts/TradeGothicLTStd-Bd2Obl.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLTStd-Bd2Obl.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TradeGothic LT Extended";
  src: url("../public/fonts/TradeGothicLT-BoldExtended.woff2") format("woff2"),
    url("../public/fonts/TradeGothicLT-BoldExtended.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  font-family: "Gothic A1";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  transition: opacity 1s;
  font-family: "Trade Gothic";
}

/* App */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

/* Accelerometer Permissions */
.accelerometer-modal {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modals */
.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.fullscreen-modal {
  background: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Video Modal */
.video-modal--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.video-modal--container > video {
  height: 100%;
  width: 100%;
}

.video-modal--thumbnail-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.video-modal--thumbnail-container > img {
  height: 100%;
  width: 100%;
}

.video-modal--close-container {
  height: 50px;
  width: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  cursor: pointer;
}

.video-modal--close-container > img {
  height: 100%;
  width: 100%;
}

.video-modal--calltoaction {
  height: 30%;
  width: auto;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  pointer-events: none;
}

.video-modal--calltoaction > img {
  height: 100%;
  width: auto;
}

/* Intro Modal */
.intro-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  color: white;
  overflow: hidden;
}

.intro-modal > p {
  color: white;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.welcome-container p {
  margin: 0;
  font-size: 2.25rem;
}

.welcome-container p:first-child {
  margin-bottom: 10px;
  font-weight: 700;
}

.adverse-container {
  background: rgba(255, 255, 255, 0.75);
  border: solid white 2px;
  color: black;
  padding: 1%;
  font-size: 1.375rem;
  max-width: 80%;
}

.adverse-container p {
  margin: 0;
}

/* End Modal */
.end-modal {
  max-height: 80%;
  pointer-events: none;
}

.end-modal img {
  height: auto;
  max-width: 80vw;
  max-height: 80vh;
}

/* Button */
.button {
  background: #007932;
  color: white;
  border: none;
  margin: 0;
  padding: 3% 6%;
  font-size: 2.25rem;
  cursor: pointer;
}

.button p {
  margin: 0;
  font-weight: 500;
}

.hide-element {
  transition: opacity 0.5s;
  opacity: 0 !important;
  pointer-events: none;
}

.show-element {
  transition: opacity 0.5s;
  opacity: 1;
}

@media only screen and (max-width: 1380px) {
  .intro-modal > p {
    width: 100%;
    font-size: 1.5rem;
  }

  .welcome-container p {
    margin: 0;
    font-size: 1.75rem;
  }

  .adverse-container {
    padding: 1%;
    margin: 0;
    font-size: 1rem;
  }

  .button {
    padding: 15px 30px;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 980px) {
  /* Intro Modal */
  .intro-modal > p {
    width: 100%;
    font-size: 1rem;
  }

  .video-modal--close-container {
    height: 30px;
    width: 30px;
  }

  .welcome-container {
    margin: 0px;
  }

  .welcome-container p {
    margin: 0;
    font-size: 1.125rem;
  }

  .welcome-container p:first-child {
    margin-bottom: 0px;
  }

  .adverse-container {
    padding: 1%;
    margin: 0;
    font-size: 0.875rem;
  }

  /* Button */
  .button {
    padding: 10px 20px;
    padding-top: 13px;
    font-size: 1.125rem;
  }
}
